import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Generate an unique `}<em parentName="h1">{`artwork`}</em>{` for your track`}</h1>
    <p>{`A friend of mine gifted me a `}<em parentName="p">{`riso`}</em>{` print with some nice abstract art on it. I asked her how she
created it and she said she used the `}<a parentName="p" {...{
        "href": "https://processing.org/"
      }}>{`processing language`}</a>{` to do so.`}</p>
    <p>{`This got me interested and I also wanted to play around with it. After reading for a bit on their
webpage I found out that there also is a javascript implementation of this called
`}<a parentName="p" {...{
        "href": "https://p5js.org/"
      }}>{`p5.js`}</a>{`.
So my first thought was nice, I can use that to do some nice backgrounds and animations for websites.`}</p>
    <p>{`After playing with it for a while and being astonished by the possibilities I wanted to do
something useful with it.`}</p>
    <p>{`So I came up with the `}<strong parentName="p">{`artwork generator`}</strong>{` that I will describe over the next pages.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      